.page-header {
  .carousel .carousel-indicators {
    bottom: 60px;
  }

  .container {
    color: $white;
  }

  .title {
    color: $white;
  }

  &.header-small {
    height: 65vh;
    min-height: 65vh;

    .container {
      padding-top: 20vh;
    }
  }
}

.landing-page {
  .content-center {
    img {
      height: 300px;
    }
  }
}

.header-filter {
  position: relative;

  .container {
    z-index: 2;
    position: relative;
  }
}
.clear-filter {
  &:before {
    display: none;
  }
}

.iframe-container iframe {
  width: 100%;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.3);
}

.header-1,
.header-2,
.header-3 {
  .wrapper {
    background: #cccccc;
  }
  .content-center {
    max-width: none !important;
  }
}

.header-2,
.header-1 {
  .page-header {
    .card {
      margin-top: 60px;
    }
  }
}

.header-3 {
  .btn {
    margin: 0;
  }
  h6 {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .buffer-hd {
    display: none;
  }
  .page-header {
    .squares {
      display: none;
    }
    .mobile-bg {
      display: block !important;
    }
    .content-center {
      .row {
        text-align: center !important;
      }
      img {
        // margin-top: 162px;
      }
      &.brand {
        .h1-seo {
          font-size: 3em;
        }
        h3 {
          display: none;
        }
      }
    }
  }
}
@media screen and (max-width: 421px) {
  .h1-seo {
    font-size: 2em !important;
  }
}
.wrapper .navbar {
  z-index: 2;
}
