@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot');
  src: url('../fonts/fontello.eot#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff2') format('woff2'),
       url('../fonts/fontello.woff') format('woff'),
       url('../fonts/fontello.ttf') format('truetype'),
       url('../fonts/fontello.svg#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ficon-"]:before, [class*=" ficon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.ficon-vcard:before { content: '\e800'; } /* '' */
.ficon-pin:before { content: '\e801'; } /* '' */
.ficon-reply:before { content: '\e802'; } /* '' */
.ficon-reply-all:before { content: '\e803'; } /* '' */
.ficon-forward:before { content: '\e804'; } /* '' */
.ficon-print:before { content: '\e805'; } /* '' */
.ficon-keyboard:before { content: '\e806'; } /* '' */
.ficon-slider-left:before { content: '\e807'; } /* '' */
.ficon-clock-empty:before { content: '\e808'; } /* '' */
.ficon-hourglass:before { content: '\e809'; } /* '' */
.ficon-login:before { content: '\e80a'; } /* '' */
.ficon-logout:before { content: '\e80b'; } /* '' */
.ficon-picture:before { content: '\e80c'; } /* '' */
.ficon-brush:before { content: '\e80d'; } /* '' */
.ficon-ccw:before { content: '\e80e'; } /* '' */
.ficon-cw:before { content: '\e80f'; } /* '' */
.ficon-refresh:before { content: '\e810'; } /* '' */
.ficon-clock:before { content: '\e811'; } /* '' */
.ficon-circle-empty:before { content: '\e812'; } /* '' */
.ficon-circle-dot:before { content: '\e813'; } /* '' */
.ficon-circle:before { content: '\e814'; } /* '' */
.ficon-checkbox:before { content: '\e815'; } /* '' */
.ficon-checkbox-empty:before { content: '\e816'; } /* '' */
.ficon-book:before { content: '\e817'; } /* '' */
.ficon-mail-empty:before { content: '\e818'; } /* '' */
.ficon-mail:before { content: '\e819'; } /* '' */
.ficon-phone:before { content: '\e81a'; } /* '' */
.ficon-book-open:before { content: '\e81b'; } /* '' */
.ficon-users-group:before { content: '\e81c'; } /* '' */
.ficon-male:before { content: '\e81d'; } /* '' */
.ficon-female:before { content: '\e81e'; } /* '' */
.ficon-child:before { content: '\e81f'; } /* '' */
.ficon-star:before { content: '\e820'; } /* '' */
.ficon-globe:before { content: '\e821'; } /* '' */
.ficon-mobile:before { content: '\e822'; } /* '' */
.ficon-tablet-1:before { content: '\e823'; } /* '' */
.ficon-laptop:before { content: '\e824'; } /* '' */
.ficon-desktop:before { content: '\e825'; } /* '' */
.ficon-cog:before { content: '\e826'; } /* '' */
.ficon-cogs:before { content: '\e827'; } /* '' */
.ficon-down:before { content: '\e828'; } /* '' */
.ficon-wrench:before { content: '\e829'; } /* '' */
.ficon-sliders:before { content: '\e82a'; } /* '' */
.ficon-lock:before { content: '\e82b'; } /* '' */
.ficon-trophy:before { content: '\e82c'; } /* '' */
.ficon-spin3:before { content: '\e82d'; } /* '' */
.ficon-spin1:before { content: '\e82e'; } /* '' */
.ficon-spin2:before { content: '\e82f'; } /* '' */
.ficon-spin4:before { content: '\e830'; } /* '' */
.ficon-spin5:before { content: '\e831'; } /* '' */
.ficon-spin6:before { content: '\e832'; } /* '' */
.ficon-location:before { content: '\e833'; } /* '' */
.ficon-address:before { content: '\e834'; } /* '' */
.ficon-up:before { content: '\e835'; } /* '' */
.ficon-right:before { content: '\e836'; } /* '' */
.ficon-left:before { content: '\e837'; } /* '' */
.ficon-music-light:before { content: '\e838'; } /* '' */
.ficon-search-light:before { content: '\e839'; } /* '' */
.ficon-mail-light:before { content: '\e83a'; } /* '' */
.ficon-heart-light:before { content: '\e83b'; } /* '' */
.ficon-dot:before { content: '\e83c'; } /* '' */
.ficon-user-light:before { content: '\e83d'; } /* '' */
.ficon-video-light:before { content: '\e83e'; } /* '' */
.ficon-camera-light:before { content: '\e83f'; } /* '' */
.ficon-photo-light:before { content: '\e840'; } /* '' */
.ficon-attach-light:before { content: '\e841'; } /* '' */
.ficon-lock-light:before { content: '\e842'; } /* '' */
.ficon-eye-light:before { content: '\e843'; } /* '' */
.ficon-tag-light:before { content: '\e844'; } /* '' */
.ficon-thumbs-up-light:before { content: '\e845'; } /* '' */
.ficon-download-1:before { content: '\e846'; } /* '' */
.ficon-comment-light:before { content: '\e847'; } /* '' */
.ficon-location-light:before { content: '\e848'; } /* '' */
.ficon-cup-light:before { content: '\e849'; } /* '' */
.ficon-trash-light:before { content: '\e84a'; } /* '' */
.ficon-doc-light:before { content: '\e84b'; } /* '' */
.ficon-key-light:before { content: '\e84c'; } /* '' */
.ficon-database-light:before { content: '\e84d'; } /* '' */
.ficon-megaphone-light:before { content: '\e84e'; } /* '' */
.ficon-graduation-light:before { content: '\e84f'; } /* '' */
.ficon-fire-light:before { content: '\e850'; } /* '' */
.ficon-paper-plane-light:before { content: '\e851'; } /* '' */
.ficon-cloud-light:before { content: '\e852'; } /* '' */
.ficon-globe-light:before { content: '\e853'; } /* '' */
.ficon-inbox-light:before { content: '\e854'; } /* '' */
.ficon-cart:before { content: '\e855'; } /* '' */
.ficon-resize-small:before { content: '\e856'; } /* '' */
.ficon-desktop-light:before { content: '\e857'; } /* '' */
.ficon-tv-light:before { content: '\e858'; } /* '' */
.ficon-wallet-light:before { content: '\e859'; } /* '' */
.ficon-t-shirt-light:before { content: '\e85a'; } /* '' */
.ficon-lightbulb-light:before { content: '\e85b'; } /* '' */
.ficon-clock-light:before { content: '\e85c'; } /* '' */
.ficon-diamond-light:before { content: '\e85d'; } /* '' */
.ficon-shop-light:before { content: '\e85e'; } /* '' */
.ficon-sound-light:before { content: '\e85f'; } /* '' */
.ficon-calendar-light:before { content: '\e860'; } /* '' */
.ficon-food-light:before { content: '\e861'; } /* '' */
.ficon-money-light:before { content: '\e862'; } /* '' */
.ficon-params-light:before { content: '\e863'; } /* '' */
.ficon-cog-light:before { content: '\e864'; } /* '' */
.ficon-beaker-light:before { content: '\e865'; } /* '' */
.ficon-note-light:before { content: '\e866'; } /* '' */
.ficon-truck-light:before { content: '\e867'; } /* '' */
.ficon-behance:before { content: '\e868'; } /* '' */
.ficon-bitbucket:before { content: '\e869'; } /* '' */
.ficon-cc:before { content: '\e86a'; } /* '' */
.ficon-codeopen:before { content: '\e86b'; } /* '' */
.ficon-css3:before { content: '\e86c'; } /* '' */
.ficon-delicious:before { content: '\e86d'; } /* '' */
.ficon-digg:before { content: '\e86e'; } /* '' */
.ficon-dribbble:before { content: '\e86f'; } /* '' */
.ficon-deviantart:before { content: '\e870'; } /* '' */
.ficon-dropbox:before { content: '\e871'; } /* '' */
.ficon-drupal:before { content: '\e872'; } /* '' */
.ficon-facebook:before { content: '\e873'; } /* '' */
.ficon-flickr:before { content: '\e874'; } /* '' */
.ficon-foursquare:before { content: '\e875'; } /* '' */
.ficon-git:before { content: '\e876'; } /* '' */
.ficon-github:before { content: '\e877'; } /* '' */
.ficon-gittip:before { content: '\e878'; } /* '' */
.ficon-google:before { content: '\e879'; } /* '' */
.ficon-gplus:before { content: '\e87a'; } /* '' */
.ficon-gwallet:before { content: '\e87b'; } /* '' */
.ficon-hacker-news:before { content: '\e87c'; } /* '' */
.ficon-html5:before { content: '\e87d'; } /* '' */
.ficon-instagramm:before { content: '\e87e'; } /* '' */
.ficon-ioxhost:before { content: '\e87f'; } /* '' */
.ficon-joomla:before { content: '\e880'; } /* '' */
.ficon-jsfiddle:before { content: '\e881'; } /* '' */
.ficon-lastfm:before { content: '\e882'; } /* '' */
.ficon-linux:before { content: '\e883'; } /* '' */
.ficon-linkedin:before { content: '\e884'; } /* '' */
.ficon-maxcdn:before { content: '\e885'; } /* '' */
.ficon-meanpath:before { content: '\e886'; } /* '' */
.ficon-openid:before { content: '\e887'; } /* '' */
.ficon-pagelines:before { content: '\e888'; } /* '' */
.ficon-paypal:before { content: '\e889'; } /* '' */
.ficon-qq:before { content: '\e88a'; } /* '' */
.ficon-reddit:before { content: '\e88b'; } /* '' */
.ficon-renren:before { content: '\e88c'; } /* '' */
.ficon-skype:before { content: '\e88d'; } /* '' */
.ficon-slack:before { content: '\e88e'; } /* '' */
.ficon-slideshare:before { content: '\e88f'; } /* '' */
.ficon-soundcloud:before { content: '\e890'; } /* '' */
.ficon-spotify:before { content: '\e891'; } /* '' */
.ficon-stackexchange:before { content: '\e892'; } /* '' */
.ficon-stackoverflow:before { content: '\e893'; } /* '' */
.ficon-steam:before { content: '\e894'; } /* '' */
.ficon-stumbleupon:before { content: '\e895'; } /* '' */
.ficon-tencent-weibo:before { content: '\e896'; } /* '' */
.ficon-trello:before { content: '\e897'; } /* '' */
.ficon-tumblr:before { content: '\e898'; } /* '' */
.ficon-twitch:before { content: '\e899'; } /* '' */
.ficon-twitter:before { content: '\e89a'; } /* '' */
.ficon-vine:before { content: '\e89b'; } /* '' */
.ficon-vkontakte:before { content: '\e89c'; } /* '' */
.ficon-wechat:before { content: '\e89d'; } /* '' */
.ficon-weibo:before { content: '\e89e'; } /* '' */
.ficon-windows:before { content: '\e89f'; } /* '' */
.ficon-wordpress:before { content: '\e8a0'; } /* '' */
.ficon-xing:before { content: '\e8a1'; } /* '' */
.ficon-yelp:before { content: '\e8a2'; } /* '' */
.ficon-youtube:before { content: '\e8a3'; } /* '' */
.ficon-yahoo:before { content: '\e8a4'; } /* '' */
.ficon-lemon:before { content: '\e8a5'; } /* '' */
.ficon-blank:before { content: '\e8a6'; } /* '' */
.ficon-pinterest-circled:before { content: '\e8a7'; } /* '' */
.ficon-heart-empty:before { content: '\e8a8'; } /* '' */
.ficon-lock-open:before { content: '\e8a9'; } /* '' */
.ficon-heart:before { content: '\e8aa'; } /* '' */
.ficon-check:before { content: '\e8ab'; } /* '' */
.ficon-cancel:before { content: '\e8ac'; } /* '' */
.ficon-plus:before { content: '\e8ad'; } /* '' */
.ficon-minus:before { content: '\e8ae'; } /* '' */
.ficon-double-left:before { content: '\e8af'; } /* '' */
.ficon-double-right:before { content: '\e8b0'; } /* '' */
.ficon-double-up:before { content: '\e8b1'; } /* '' */
.ficon-double-down:before { content: '\e8b2'; } /* '' */
.ficon-help:before { content: '\e8b3'; } /* '' */
.ficon-info:before { content: '\e8b4'; } /* '' */
.ficon-link:before { content: '\e8b5'; } /* '' */
.ficon-quote:before { content: '\e8b6'; } /* '' */
.ficon-attach:before { content: '\e8b7'; } /* '' */
.ficon-eye:before { content: '\e8b8'; } /* '' */
.ficon-tag:before { content: '\e8b9'; } /* '' */
.ficon-menu:before { content: '\e8ba'; } /* '' */
.ficon-pencil:before { content: '\e8bb'; } /* '' */
.ficon-feather:before { content: '\e8bc'; } /* '' */
.ficon-code:before { content: '\e8bd'; } /* '' */
.ficon-home:before { content: '\e8be'; } /* '' */
.ficon-camera:before { content: '\e8bf'; } /* '' */
.ficon-pictures:before { content: '\e8c0'; } /* '' */
.ficon-video:before { content: '\e8c1'; } /* '' */
.ficon-users:before { content: '\e8c2'; } /* '' */
.ficon-user:before { content: '\e8c3'; } /* '' */
.ficon-user-add:before { content: '\e8c4'; } /* '' */
.ficon-comment-empty:before { content: '\e8c5'; } /* '' */
.ficon-chat-empty:before { content: '\e8c6'; } /* '' */
.ficon-bell:before { content: '\e8c7'; } /* '' */
.ficon-star-empty:before { content: '\e8c8'; } /* '' */
.ficon-dot2:before { content: '\e8c9'; } /* '' */
.ficon-dot3:before { content: '\e8ca'; } /* '' */
.ficon-note:before { content: '\e8cb'; } /* '' */
.ficon-basket:before { content: '\e8cc'; } /* '' */
.ficon-note2:before { content: '\e8cd'; } /* '' */
.ficon-share:before { content: '\e8ce'; } /* '' */
.ficon-doc:before { content: '\e8cf'; } /* '' */
.ficon-doc-text:before { content: '\e8d0'; } /* '' */
.ficon-file-pdf:before { content: '\e8d1'; } /* '' */
.ficon-file-word:before { content: '\e8d2'; } /* '' */
.ficon-file-excel:before { content: '\e8d3'; } /* '' */
.ficon-file-powerpoint:before { content: '\e8d4'; } /* '' */
.ficon-file-image:before { content: '\e8d5'; } /* '' */
.ficon-file-archive:before { content: '\e8d6'; } /* '' */
.ficon-file-audio:before { content: '\e8d7'; } /* '' */
.ficon-file-video:before { content: '\e8d8'; } /* '' */
.ficon-file-code:before { content: '\e8d9'; } /* '' */
.ficon-trash-empty:before { content: '\e8da'; } /* '' */
.ficon-trash:before { content: '\e8db'; } /* '' */
.ficon-th-large:before { content: '\e8dc'; } /* '' */
.ficon-th:before { content: '\e8dd'; } /* '' */
.ficon-th-list:before { content: '\e8de'; } /* '' */
.ficon-flag:before { content: '\e8df'; } /* '' */
.ficon-attention:before { content: '\e8e0'; } /* '' */
.ficon-play:before { content: '\e8e1'; } /* '' */
.ficon-stop:before { content: '\e8e2'; } /* '' */
.ficon-pause:before { content: '\e8e3'; } /* '' */
.ficon-record:before { content: '\e8e4'; } /* '' */
.ficon-to-end:before { content: '\e8e5'; } /* '' */
.ficon-tools:before { content: '\e8e6'; } /* '' */
.ficon-retweet:before { content: '\e8e7'; } /* '' */
.ficon-to-start:before { content: '\e8e8'; } /* '' */
.ficon-fast-forward:before { content: '\e8e9'; } /* '' */
.ficon-fast-backward:before { content: '\e8ea'; } /* '' */
.ficon-search:before { content: '\e8eb'; } /* '' */
.ficon-slider-right:before { content: '\e8ec'; } /* '' */
.ficon-rss:before { content: '\e8ed'; } /* '' */
.ficon-down-big-1:before { content: '\e8ee'; } /* '' */
.ficon-left-big-1:before { content: '\e8ef'; } /* '' */
.ficon-right-big-1:before { content: '\e8f0'; } /* '' */
.ficon-up-big-1:before { content: '\e8f1'; } /* '' */
.ficon-comment-2:before { content: '\e8f2'; } /* '' */
.ficon-comment:before { content: '\e8f3'; } /* '' */
.ficon-eye-2:before { content: '\e8f4'; } /* '' */
.ficon-tablet:before { content: '\e8f5'; } /* '' */
.ficon-bathtub:before { content: '\e8f6'; } /* '' */
.ficon-comb:before { content: '\e8f7'; } /* '' */
.ficon-calendar:before { content: '\e8f8'; } /* '' */
.ficon-search-alt:before { content: '\e8f9'; } /* '' */
.ficon-white:before { content: '\e8fa'; } /* '' */
.ficon-home-alt:before { content: '\e8fb'; } /* '' */
.ficon-timer:before { content: '\e8fc'; } /* '' */
.ficon-locator:before { content: '\e8fd'; } /* '' */
.ficon-cup:before { content: '\e8fe'; } /* '' */
.ficon-currency:before { content: '\e8ff'; } /* '' */
.ficon-box:before { content: '\e900'; } /* '' */
.ficon-quote-left:before { content: '\e901'; } /* '' */
.ficon-quote-right:before { content: '\e902'; } /* '' */
.ficon-volume-up:before { content: '\e903'; } /* '' */
.ficon-volume-off:before { content: '\e904'; } /* '' */
.ficon-volume:before { content: '\e905'; } /* '' */
.ficon-volume-down:before { content: '\e906'; } /* '' */
.ficon-arrow-left-alt:before { content: '\e907'; } /* '' */
.ficon-arrow-right-alt:before { content: '\e908'; } /* '' */
.ficon-thumbs-up:before { content: '\e909'; } /* '' */
.ficon-thumbs-down:before { content: '\e90a'; } /* '' */
.ficon-speech:before { content: '\e90b'; } /* '' */
.ficon-sphere:before { content: '\e90c'; } /* '' */
.ficon-light:before { content: '\e90d'; } /* '' */
.ficon-apple-mouse:before { content: '\e90e'; } /* '' */
.ficon-arrow-down:before { content: '\e90f'; } /* '' */
.ficon-arrow-left:before { content: '\e910'; } /* '' */
.ficon-arrow-right:before { content: '\e911'; } /* '' */
.ficon-arrow-up:before { content: '\e912'; } /* '' */
.ficon-search-2:before { content: '\e913'; } /* '' */
.ficon-cd-light:before { content: '\e914'; } /* '' */
.ficon-mobile-light:before { content: '\e915'; } /* '' */
.ficon-clear-button:before { content: '\e916'; } /* '' */
.ficon-binoculars:before { content: '\e917'; } /* '' */
.ficon-resize-full:before { content: '\e918'; } /* '' */
.ficon-truck:before { content: '\e919'; } /* '' */
.ficon-clipboard:before { content: '\e91a'; } /* '' */
.ficon-email:before { content: '\e91b'; } /* '' */
.ficon-modem:before { content: '\e91c'; } /* '' */
.ficon-parking:before { content: '\e91d'; } /* '' */
.ficon-swimming-pool:before { content: '\e91e'; } /* '' */
.ficon-user-alt:before { content: '\e91f'; } /* '' */
.ficon-user-plus:before { content: '\e920'; } /* '' */
.ficon-user-times:before { content: '\e921'; } /* '' */
.ficon-ellipsis:before { content: '\e922'; } /* '' */
.ficon-summer:before { content: '\e923'; } /* '' */
.ficon-wireless-antenna:before { content: '\e924'; } /* '' */
.ficon-floor:before { content: '\e925'; } /* '' */
.ficon-home-1:before { content: '\e926'; } /* '' */
.ficon-wireless:before { content: '\e927'; } /* '' */
.ficon-ironing:before { content: '\e928'; } /* '' */
.ficon-down-big:before { content: '\e929'; } /* '' */
.ficon-left-big:before { content: '\e92a'; } /* '' */
.ficon-right-big:before { content: '\e92b'; } /* '' */
.ficon-up-big:before { content: '\e92c'; } /* '' */
.ficon-laundry:before { content: '\e92d'; } /* '' */
.ficon-fax-2:before { content: '\e92e'; } /* '' */
.ficon-phone-2:before { content: '\e92f'; } /* '' */
.ficon-tools-1:before { content: '\e930'; } /* '' */
.ficon-checkbox-2:before { content: '\e931'; } /* '' */
.ficon-map:before { content: '\e932'; } /* '' */
.ficon-newspaper:before { content: '\e933'; } /* '' */
.ficon-note-2:before { content: '\e934'; } /* '' */
.ficon-picture-2:before { content: '\e935'; } /* '' */
.ficon-radio-tower:before { content: '\e936'; } /* '' */
.ficon-video-2:before { content: '\e937'; } /* '' */
.ficon-down-arrow:before { content: '\e938'; } /* '' */
.ficon-left-arrow:before { content: '\e939'; } /* '' */
.ficon-right-arrow:before { content: '\e93a'; } /* '' */
.ficon-up-arrow:before { content: '\e93b'; } /* '' */
.ficon-menu-2:before { content: '\e93c'; } /* '' */
.ficon-cart-1:before { content: '\e93d'; } /* '' */
.ficon-ellipsis-vert:before { content: '\e93e'; } /* '' */
.ficon-left-mini:before { content: '\e93f'; } /* '' */
.ficon-right-mini:before { content: '\e940'; } /* '' */
.ficon-up-mini:before { content: '\e941'; } /* '' */
.ficon-down-mini:before { content: '\e942'; } /* '' */
.ficon-arrow-combo:before { content: '\e943'; } /* '' */
.ficon-signal:before { content: '\e944'; } /* '' */
.ficon-air:before { content: '\e945'; } /* '' */
.ficon-water:before { content: '\e946'; } /* '' */
.ficon-shareable:before { content: '\e947'; } /* '' */
.ficon-resize-full-2:before { content: '\e948'; } /* '' */
.ficon-resize-small-1:before { content: '\e949'; } /* '' */
.ficon-down-thin:before { content: '\e94a'; } /* '' */
.ficon-left-thin:before { content: '\e94b'; } /* '' */
.ficon-right-thin:before { content: '\e94c'; } /* '' */
.ficon-up-thin:before { content: '\e94d'; } /* '' */
.ficon-level-down:before { content: '\e94e'; } /* '' */
.ficon-level-up:before { content: '\e94f'; } /* '' */
.ficon-shuffle:before { content: '\e950'; } /* '' */
.ficon-loop:before { content: '\e951'; } /* '' */
.ficon-switch:before { content: '\e952'; } /* '' */
.ficon-resize-vertical:before { content: '\e953'; } /* '' */
.ficon-resize-horizontal:before { content: '\e954'; } /* '' */
.ficon-block:before { content: '\e955'; } /* '' */
.ficon-publish:before { content: '\e956'; } /* '' */
.ficon-window:before { content: '\e957'; } /* '' */
.ficon-photo:before { content: '\e958'; } /* '' */
.ficon-download:before { content: '\e959'; } /* '' */
.ficon-upload:before { content: '\e95a'; } /* '' */
.ficon-link-2:before { content: '\e95b'; } /* '' */
.ficon-attach-1:before { content: '\e95c'; } /* '' */
.ficon-font:before { content: '\e95d'; } /* '' */
.ficon-home-2:before { content: '\e95e'; } /* '' */
.ficon-page:before { content: '\e95f'; } /* '' */
.ficon-plugins:before { content: '\e960'; } /* '' */
.ficon-settings:before { content: '\e961'; } /* '' */
.ficon-smartphone:before { content: '\e962'; } /* '' */
.ficon-check-2:before { content: '\e963'; } /* '' */
.ficon-customizer:before { content: '\e964'; } /* '' */
.ficon-editor-table:before { content: '\e965'; } /* '' */
.ficon-footer:before { content: '\e966'; } /* '' */
.ficon-header:before { content: '\e967'; } /* '' */
.ficon-plus-2:before { content: '\e968'; } /* '' */
.ficon-refresh-2:before { content: '\e969'; } /* '' */
.ficon-cart-2:before { content: '\e96a'; } /* '' */
.ficon-editor-code:before { content: '\e96b'; } /* '' */
.ficon-network:before { content: '\e96c'; } /* '' */
.ficon-share-2:before { content: '\e96d'; } /* '' */
.ficon-star-filled:before { content: '\e96e'; } /* '' */
.ficon-tag-2:before { content: '\e96f'; } /* '' */
.ficon-tools-2:before { content: '\e970'; } /* '' */
.ficon-building:before { content: '\e971'; } /* '' */
.ficon-document:before { content: '\e972'; } /* '' */
.ficon-format-image:before { content: '\e973'; } /* '' */
.ficon-location-2:before { content: '\e974'; } /* '' */
.ficon-email-2:before { content: '\e975'; } /* '' */
.ficon-portfolio:before { content: '\e976'; } /* '' */
.ficon-screenoptions:before { content: '\e977'; } /* '' */
.ficon-settings-2:before { content: '\e978'; } /* '' */
.ficon-users-2:before { content: '\e979'; } /* '' */
.ficon-text:before { content: '\e97a'; } /* '' */
.ficon-padlock:before { content: '\e97b'; } /* '' */
.ficon-padlock-unlock:before { content: '\e97c'; } /* '' */
.ficon-down-dir:before { content: '\e97d'; } /* '' */
.ficon-left-dir:before { content: '\e97e'; } /* '' */
.ficon-right-dir:before { content: '\e97f'; } /* '' */
.ficon-up-dir:before { content: '\e980'; } /* '' */
.ficon-pencil-1:before { content: '\e981'; } /* '' */
.ficon-pencil-2:before { content: '\e982'; } /* '' */
.ficon-pencil-alt:before { content: '\e983'; } /* '' */
.ficon-pencil-3:before { content: '\e984'; } /* '' */
.ficon-warning:before { content: '\e985'; } /* '' */
.ficon-yes-alt:before { content: '\e986'; } /* '' */
.ficon-attention-1:before { content: '\e987'; } /* '' */
.ficon-play-big:before { content: '\e988'; } /* '' */
.ficon-stop-big:before { content: '\e989'; } /* '' */
.ficon-pause-big:before { content: '\e98a'; } /* '' */
.ficon-to-end-big:before { content: '\e98b'; } /* '' */
.ficon-to-end-big-alt:before { content: '\e98c'; } /* '' */
.ficon-to-start-big:before { content: '\e98d'; } /* '' */
.ficon-to-start-big-alt:before { content: '\e98e'; } /* '' */
.ficon-fast-fw-big:before { content: '\e98f'; } /* '' */
.ficon-fast-bw-big:before { content: '\e990'; } /* '' */
.ficon-eject-big:before { content: '\e991'; } /* '' */
.ficon-volume-off-big:before { content: '\e992'; } /* '' */
.ficon-volume-down-big:before { content: '\e993'; } /* '' */
.ficon-volume-up-big:before { content: '\e994'; } /* '' */
.ficon-whatsapp:before { content: '\e995'; } /* '' */
.ficon-connectdevelop:before { content: '\e996'; } /* '' */
.ficon-dashcube:before { content: '\e997'; } /* '' */
.ficon-apple:before { content: '\e998'; } /* '' */
.ficon-angellist:before { content: '\e999'; } /* '' */
.ficon-android:before { content: '\e99a'; } /* '' */
.ficon-adn:before { content: '\e99b'; } /* '' */
.ficon-buysellads:before { content: '\e99c'; } /* '' */
.ficon-pinterest:before { content: '\e99d'; } /* '' */
.ficon-shirtsinbulk:before { content: '\e99e'; } /* '' */
.ficon-skyatlas:before { content: '\e99f'; } /* '' */
.ficon-pied-piper-alt:before { content: '\e9a0'; } /* '' */
.ficon-quote-1:before { content: '\e9a1'; } /* '' */
.ficon-correct:before { content: '\e9a2'; } /* '' */
.ficon-envelope:before { content: '\e9a3'; } /* '' */
.ficon-path-3443:before { content: '\e9a4'; } /* '' */
.ficon-group-8631:before { content: '\e9a5'; } /* '' */
.ficon-search-3:before { content: '\e9a6'; } /* '' */
.ficon-flash:before { content: '\e9a7'; } /* '' */
.ficon-statue-of-liberty:before { content: '\e9a8'; } /* '' */
.ficon-target-2:before { content: '\e9a9'; } /* '' */
.ficon-targeting:before { content: '\e9aa'; } /* '' */
.ficon-trophy-1:before { content: '\e9ab'; } /* '' */
.ficon-truck-1:before { content: '\e9ac'; } /* '' */
.ficon-twitter-1:before { content: '\e9ad'; } /* '' */
.ficon-user-1:before { content: '\e9ae'; } /* '' */
.ficon-volume-control-off:before { content: '\e9af'; } /* '' */
.ficon-volume-control-on:before { content: '\e9b0'; } /* '' */
.ficon-wall-clock:before { content: '\e9b1'; } /* '' */
.ficon-arrow-long:before { content: '\e9b2'; } /* '' */
.ficon-bag:before { content: '\e9b3'; } /* '' */
.ficon-behance-1:before { content: '\e9b4'; } /* '' */
.ficon-big-ben:before { content: '\e9b5'; } /* '' */
.ficon-big-loupe:before { content: '\e9b6'; } /* '' */
.ficon-calendar-events:before { content: '\e9b7'; } /* '' */
.ficon-call:before { content: '\e9b8'; } /* '' */
.ficon-check-1:before { content: '\e9b9'; } /* '' */
.ficon-clock-1:before { content: '\e9ba'; } /* '' */
.ficon-contract:before { content: '\e9bb'; } /* '' */
.ficon-conversation:before { content: '\e9bc'; } /* '' */
.ficon-coupon:before { content: '\e9bd'; } /* '' */
.ficon-database:before { content: '\e9be'; } /* '' */
.ficon-deadline:before { content: '\e9bf'; } /* '' */
.ficon-delivery:before { content: '\e9c0'; } /* '' */
.ficon-diamond:before { content: '\e9c1'; } /* '' */
.ficon-dribbble-1:before { content: '\e9c2'; } /* '' */
.ficon-eiffel-tower:before { content: '\e9c3'; } /* '' */
.ficon-email-1:before { content: '\e9c4'; } /* '' */
.ficon-employee:before { content: '\e9c5'; } /* '' */
.ficon-facebook-1:before { content: '\e9c6'; } /* '' */
.ficon-fingerprint:before { content: '\e9c7'; } /* '' */
.ficon-group-2438:before { content: '\e9c8'; } /* '' */
.ficon-instagram:before { content: '\e9c9'; } /* '' */
.ficon-group-8830:before { content: '\e9ca'; } /* '' */
.ficon-group-8921:before { content: '\e9cb'; } /* '' */
.ficon-heart-1:before { content: '\e9cc'; } /* '' */
.ficon-help-1:before { content: '\e9cd'; } /* '' */
.ficon-marker-new:before { content: '\e9ce'; } /* '' */
.ficon-iphone:before { content: '\e9cf'; } /* '' */
.ficon-location-1:before { content: '\e9d0'; } /* '' */
.ficon-reaction:before { content: '\e9d1'; } /* '' */
.ficon-marker:before { content: '\e9d2'; } /* '' */
.ficon-menu-1:before { content: '\e9d3'; } /* '' */
.ficon-minus-1:before { content: '\e9d4'; } /* '' */
.ficon-molecular:before { content: '\e9d5'; } /* '' */
.ficon-mouse:before { content: '\e9d6'; } /* '' */
.ficon-open-menu:before { content: '\e9d7'; } /* '' */
.ficon-page-1:before { content: '\e9d8'; } /* '' */
.ficon-paper-plane:before { content: '\e9d9'; } /* '' */
.ficon-path-3314:before { content: '\e9da'; } /* '' */
.ficon-path-1:before { content: '\e9db'; } /* '' */
.ficon-promotion:before { content: '\e9dc'; } /* '' */
.ficon-phone-call:before { content: '\e9dd'; } /* '' */
.ficon-path-4230:before { content: '\e9de'; } /* '' */
.ficon-path-4452:before { content: '\e9df'; } /* '' */
.ficon-path-6096:before { content: '\e9e0'; } /* '' */
.ficon-path-6189:before { content: '\e9e1'; } /* '' */
.ficon-path-6190:before { content: '\e9e2'; } /* '' */
.ficon-path-6191:before { content: '\e9e3'; } /* '' */
.ficon-path-6192:before { content: '\e9e4'; } /* '' */
.ficon-path-6508:before { content: '\e9e5'; } /* '' */
.ficon-path-6509:before { content: '\e9e6'; } /* '' */
.ficon-pencil-4:before { content: '\e9e7'; } /* '' */
.ficon-phone-1:before { content: '\e9e8'; } /* '' */
.ficon-play-button:before { content: '\e9e9'; } /* '' */
.ficon-plus-1:before { content: '\e9ea'; } /* '' */
.ficon-quote-2:before { content: '\e9eb'; } /* '' */
.ficon-right-bar:before { content: '\e9ec'; } /* '' */
.ficon-right-arrow-1:before { content: '\e9ed'; } /* '' */
.ficon-share-1:before { content: '\e9ee'; } /* '' */
.ficon-shop:before { content: '\e9ef'; } /* '' */
.ficon-startup:before { content: '\e9f0'; } /* '' */
.ficon-star-1:before { content: '\e9f1'; } /* '' */
.ficon-st2ar:before { content: '\e9f2'; } /* '' */
.ficon-sms:before { content: '\e9f3'; } /* '' */
.ficon-shopping-paper-bag-:before { content: '\e9f4'; } /* '' */
.ficon-shopping-cart:before { content: '\e9f5'; } /* '' */
.ficon-rectangle-3533:before { content: '\e9f6'; } /* '' */
.ficon-trophy-2:before { content: '\e9f7'; } /* '' */
.ficon-webcam:before { content: '\e9f8'; } /* '' */
.ficon-startup-1:before { content: '\e9f9'; } /* '' */
.ficon-target:before { content: '\e9fa'; } /* '' */
.ficon-bullets:before { content: '\e9fb'; } /* '' */
.ficon-envelope-2:before { content: '\e9fc'; } /* '' */
.ficon-browser:before { content: '\e9fd'; } /* '' */
.ficon-lay:before { content: '\e9fe'; } /* '' */
.ficon-pen:before { content: '\e9ff'; } /* '' */
.ficon-clock-2:before { content: '\ea00'; } /* '' */
.ficon-location-pin:before { content: '\ea01'; } /* '' */
.ficon-twitter-new:before { content: '\ea02'; } /* '' */
.ficon-dribble-new:before { content: '\ea03'; } /* '' */
.ficon-path-2:before { content: '\ea04'; } /* '' */
.ficon-heart-2:before { content: '\ea05'; } /* '' */
.ficon-tick:before { content: '\ea06'; } /* '' */
.ficon-small-left-arrow:before { content: '\ea07'; } /* '' */
.ficon-short-arrow-vertical:before { content: '\ea08'; } /* '' */
.ficon-long-arrow-vertical:before { content: '\ea09'; } /* '' */
.ficon-cookie:before { content: '\ea0a'; } /* '' */
.ficon-support-style:before { content: '\ea0b'; } /* '' */
.ficon-shop-style:before { content: '\ea0c'; } /* '' */
.ficon-shopping-bag-style:before { content: '\ea0d'; } /* '' */
.ficon-sales-style:before { content: '\ea0e'; } /* '' */
.ficon-question-style:before { content: '\ea0f'; } /* '' */
.ficon-volume-1:before { content: '\ea10'; } /* '' */
.ficon-share-email:before { content: '\ea11'; } /* '' */
.ficon-avatar:before { content: '\ea12'; } /* '' */
.ficon-slider-right-ok:before { content: '\ea13'; } /* '' */
.ficon-slider-left-ok:before { content: '\ea14'; } /* '' */
.ficon-fonts-des:before { content: '\ea15'; } /* '' */
.ficon-primary-colors-des:before { content: '\ea16'; } /* '' */
.ficon-pen-tablet-des:before { content: '\ea17'; } /* '' */
.ficon-decor-arrow-des:before { content: '\ea18'; } /* '' */
.ficon-service-icon-2:before { content: '\ea19'; } /* '' */
.ficon-service-icon-3:before { content: '\ea1a'; } /* '' */
.ficon-service-icon-4:before { content: '\ea1b'; } /* '' */
.ficon-service-icon-5:before { content: '\ea1c'; } /* '' */
.ficon-service-icon-6:before { content: '\ea1d'; } /* '' */
.ficon-service-icon-1:before { content: '\ea1e'; } /* '' */
.ficon-quotes-testi:before { content: '\ea1f'; } /* '' */
.ficon-service-icon-8:before { content: '\ea20'; } /* '' */
.ficon-service-icon-9:before { content: '\ea21'; } /* '' */
.ficon-service-icon-7:before { content: '\ea22'; } /* '' */
.ficon-activity:before { content: '\ea5a'; } /* '' */
.ficon-aromatherapy:before { content: '\ea5b'; } /* '' */
.ficon-balance:before { content: '\ea5c'; } /* '' */
.ficon-bamboo:before { content: '\ea5d'; } /* '' */
.ficon-candle:before { content: '\ea5e'; } /* '' */
.ficon-clipboard-1:before { content: '\ea5f'; } /* '' */
.ficon-diet:before { content: '\ea60'; } /* '' */
.ficon-face:before { content: '\ea61'; } /* '' */
.ficon-facial-massage:before { content: '\ea62'; } /* '' */
.ficon-hand:before { content: '\ea63'; } /* '' */
.ficon-health:before { content: '\ea64'; } /* '' */
.ficon-hot-stones:before { content: '\ea65'; } /* '' */
.ficon-meditation:before { content: '\ea66'; } /* '' */
.ficon-lotos:before { content: '\ea67'; } /* '' */
.ficon-mind:before { content: '\ea68'; } /* '' */
.ficon-peace:before { content: '\ea69'; } /* '' */
.ficon-stones:before { content: '\ea6a'; } /* '' */
.ficon-yoga-coach:before { content: '\ea6b'; } /* '' */
.ficon-percent:before { content: '\ea6c'; } /* '' */
.ficon-pocket:before { content: '\ea6d'; } /* '' */
.ficon-present:before { content: '\ea6e'; } /* '' */
.ficon-wallet-cash:before { content: '\ea6f'; } /* '' */
.ficon-call-solid:before { content: '\ea70'; } /* '' */
.ficon-user-solid:before { content: '\ea71'; } /* '' */
.ficon-email-solid:before { content: '\ea72'; } /* '' */
.ficon-plus-3:before { content: '\ea73'; } /* '' */
.ficon-healing-essence:before { content: '\ea74'; } /* '' */
.ficon-renewed-wellness:before { content: '\ea75'; } /* '' */
.ficon-down-open:before { content: '\f004'; } /* '' */
.ficon-up-open:before { content: '\f005'; } /* '' */
.ficon-move:before { content: '\f047'; } /* '' */
.ficon-resize-full-alt:before { content: '\f0b2'; } /* '' */
.ficon-docs:before { content: '\f0c5'; } /* '' */
.ficon-columns:before { content: '\f0db'; } /* '' */
.ficon-ambulance:before { content: '\f0f9'; } /* '' */
.ficon-unlink-2:before { content: '\f127'; } /* '' */
.ficon-euro:before { content: '\f153'; } /* '' */
.ficon-pound:before { content: '\f154'; } /* '' */
.ficon-dollar:before { content: '\f155'; } /* '' */
.ficon-yen:before { content: '\f157'; } /* '' */
.ficon-rouble:before { content: '\f158'; } /* '' */
.ficon-car:before { content: '\f1b9'; } /* '' */
.ficon-taxi:before { content: '\f1ba'; } /* '' */
.ficon-cc-visa:before { content: '\f1f0'; } /* '' */
.ficon-cc-mastercard:before { content: '\f1f1'; } /* '' */
.ficon-cc-discover:before { content: '\f1f2'; } /* '' */
.ficon-cc-amex:before { content: '\f1f3'; } /* '' */
.ficon-cc-paypal:before { content: '\f1f4'; } /* '' */
.ficon-cc-stripe:before { content: '\f1f5'; } /* '' */
.ficon-bicycle:before { content: '\f206'; } /* '' */
.ficon-bus:before { content: '\f207'; } /* '' */
.ficon-ship:before { content: '\f21a'; } /* '' */
.ficon-motorcycle:before { content: '\f21c'; } /* '' */
.ficon-genderless:before { content: '\f22d'; } /* '' */
.ficon-train:before { content: '\f238'; } /* '' */
.ficon-subway:before { content: '\f239'; } /* '' */
.ficon-optin-monster:before { content: '\f23c'; } /* '' */
.ficon-opencart:before { content: '\f23d'; } /* '' */
.ficon-clone:before { content: '\f24d'; } /* '' */
.ficon-telegram:before { content: '\f2c6'; } /* '' */
.ficon-window-maximize:before { content: '\f2d0'; } /* '' */
.ficon-bb-buddy-press:before { content: '\f501'; } /* '' */
.ficon-cars:before { content: '\f502'; } /* '' */
.ficon-category:before { content: '\f503'; } /* '' */
.ficon-certificates:before { content: '\f504'; } /* '' */
.ficon-courses:before { content: '\f505'; } /* '' */
.ficon-dishes:before { content: '\f506'; } /* '' */
.ficon-portfolio-1:before { content: '\f507'; } /* '' */
.ficon-posts-page:before { content: '\f508'; } /* '' */
.ficon-resume:before { content: '\f509'; } /* '' */
.ficon-search-1:before { content: '\f50a'; } /* '' */
.ficon-services:before { content: '\f50b'; } /* '' */
.ficon-sport:before { content: '\f50c'; } /* '' */
.ficon-team:before { content: '\f50d'; } /* '' */
.ficon-testimonials:before { content: '\f50e'; } /* '' */
.ficon-additional-css:before { content: '\f50f'; } /* '' */
.ficon-homepage-settings:before { content: '\f510'; } /* '' */
.ficon-blog:before { content: '\f511'; } /* '' */
.ficon-single-post:before { content: '\f512'; } /* '' */
.ficon-tag-1:before { content: '\f513'; } /* '' */
.ficon-donation:before { content: '\f514'; } /* '' */
.ficon-downloads:before { content: '\f515'; } /* '' */
.ficon-events:before { content: '\f516'; } /* '' */
