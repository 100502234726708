/* You can add global styles to this file, and also import other style files */

@import "assets/scss/colors.scss";
@import "assets/scss/span.scss";
@import "assets/scss/icon.scss";
@import "assets/scss/fontello.scss";

@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Black+Ops+One&family=Cabin:wght@700&family=Caveat&family=Chakra+Petch:wght@300&family=Cinzel&family=Cinzel+Decorative:wght@400;700;900&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,600&family=Lato:wght@900&family=Macondo&family=Michroma&family=Noto+Sans+Indic+Siyaq+Numbers&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Orbitron:wght@400;900&family=Philosopher&family=Rajdhani:wght@300;400;500;600;700&family=Rubik+Distressed&family=Special+Elite&family=Syncopate:wght@400;700&family=Urbanist:wght@600&display=swap');

.page-header {
  .filter {
    &::after{
      background-color: rgba(0, 0, 0, 0.5);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
      width: 100%;
      z-index: 1;
    }
    & + .container {
      position: relative;
      z-index: 2;
    }
  }

  &.page-header-small{
    min-height: 65vh !important;
    max-height: 700px;
  }

  &.page-header-xs{
    min-height: 40vh !important;
  }
}


.animated.animated-slow {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms
}

.animated {
  -webkit-animation-duration: 750ms;
  animation-duration: 750ms
}

.animated.animated-fast {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms
}

.fadeinup {
  -webkit-animation-name: fadeinup;
  animation-name: fadeinup;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease
}

@-webkit-keyframes fadeinup {
  from {
      opacity: 0;
      -webkit-transform: translateY(60px);
      transform: translateY(60px)
  }

  to {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}

@keyframes fadeinup {
  from {
      opacity: 0;
      -webkit-transform: translateY(60px);
      transform: translateY(60px)
  }

  to {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}

.fadeinleft {
  -webkit-animation-name: fadeinleft;
  animation-name: fadeinleft;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease
}

@-webkit-keyframes fadeinleft {
  from {
      opacity: 0;
      -webkit-transform: translateX(-60px);
      transform: translateX(-60px)
  }

  to {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }
}

@keyframes fadeinleft {
  from {
      opacity: 0;
      -webkit-transform: translateX(-60px);
      transform: translateX(-60px)
  }

  to {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }
}

.fadeinright {
  -webkit-animation-name: fadeinright;
  animation-name: fadeinright;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease
}

@-webkit-keyframes fadeinright {
  from {
      opacity: 0;
      -webkit-transform: translateX(60px);
      transform: translateX(60px)
  }

  to {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }
}

@keyframes fadeinright {
  from {
      opacity: 0;
      -webkit-transform: translateX(60px);
      transform: translateX(60px)
  }

  to {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }
}

.fadeindown {
  -webkit-animation-name: fadeindown;
  animation-name: fadeindown;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease
}

@-webkit-keyframes fadeindown {
  from {
      opacity: 0;
      -webkit-transform: translateY(-60px);
      transform: translateY(-60px)
  }

  to {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}

@keyframes fadeindown {
  from {
      opacity: 0;
      -webkit-transform: translateY(-60px);
      transform: translateY(-60px)
  }

  to {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}

.fadein {
  -webkit-animation-name: fadein;
  animation-name: fadein;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease
}

@-webkit-keyframes fadein {
  from {
      opacity: 0
  }

  to {
      opacity: 1
  }
}

@keyframes fadein {
  from {
      opacity: 0
  }

  to {
      opacity: 1
  }
}

.infinite-rotate {
  animation: infinite-rotate 10s linear infinite
}

@-webkit-keyframes infinite-rotate {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  to {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn)
  }
}

@-o-keyframes infinite-rotate {
  0% {
      -o-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  to {
      -o-transform: rotate(1turn);
      transform: rotate(1turn)
  }
}

@keyframes infinite-rotate {
  0% {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  to {
      -webkit-transform: rotate(1turn);
      -o-transform: rotate(1turn);
      transform: rotate(1turn)
  }
}