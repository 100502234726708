.link-more {
  width: 1.8em;
  height: 1.8em;
  line-height: 1.8em;
  text-align: center;
  &:before {
    font-family: "fontello";
    content: '\E8CA';
    font-size: 28px;
    font-weight: 400;
    -webkit-transition: color 0.3s ease-out;
    -ms-transition: color 0.3s ease-out;
    transition: color 0.3s ease-out;
  }
}

.card-icon {
  font-size: 4.8em;
}